<template>
  <div class="adminbackground">
    <appbar></appbar>
    <v-row justify="center" class="mt-5">
      <v-col md="9" sm="12">
        <v-data-table dense :headers="headers" :items="tableItems" hide-default-footer disable-pagination
          class="elevation-1">
          <template v-slot:top>
            <v-row class="d-flex align-start mx-5">
              <v-col cols=12>
                <h2>Workspaces</h2>
              </v-col>
            </v-row>
            <v-row class="d-flex align-center mx-5">
              <v-col cols=8>
                <v-text-field v-model="search" label="Search"></v-text-field>
              </v-col>
              <v-icon large v-if="search.length > 0" class="close-button" @click="search = ''">mdi-close</v-icon>
              <v-col cols=3>
                <v-btn :class="search.length > 0 && 'margin-left-10'" tile outlined @click="newitem()"><v-icon small
                    class="py-2 mr-2">mdi-flip-to-front</v-icon> New</v-btn>
              </v-col>
            </v-row>

          </template>


          <template v-slot:item.action="{ item }">
            <v-icon medium class="pa-2" @click="editItem(item)">mdi-pencil</v-icon>
            <v-icon medium class="pa-2" @click="deleteItem(item)">mdi-delete</v-icon>
          </template>
        </v-data-table>



        <v-dialog v-model="dialog" max-width="800px" class="dialogbox" eager>
          <v-card>
            <v-card-title>
              <span class="headline">{{ this.editedItem.id === 0 ? 'New' : 'Edit' }}</span>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-form v-model="isFormValid" ref="workspaceform">
                  <v-row>
                    <v-col cols="12">
                      <v-text-field v-model="editedItem.name" label="Name" :rules="reqRules"></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="6">
                      <v-text-field v-model="editedItem.location" label="Location" :rules="reqRules"></v-text-field>
                    </v-col>
                    <v-col cols="6">
                      <v-combobox required :rules="reqRules" item-value="id" :item-text="getUserFullName"
                      v-model="userSelected" :items="userItems" label="Primary User"></v-combobox>
                    </v-col>
                  </v-row>

                </v-form>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn tile outlined class="mb-3" @click="dialog = false"><v-icon small class="py-2 mr-1">mdi-close</v-icon>
                Cancel</v-btn>
              <v-btn tile outlined class="mb-3 mr-3" @click="save()" :disabled="!isFormValid"><v-icon small
                  class="py-2 mr-1">mdi-content-save</v-icon> Save</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>


      </v-col>
    </v-row>
  </div>
</template>

<script>

import { HTTP } from '@/plugins/backendAPI'
import { mapGetters } from 'vuex'
import { EventBus } from '@/store/event-bus.js'

export default {
  data() {
    return {
      dialog: false,
      search: '',
      footerProps: { 'items-per-page-options': [50, 100] },
      headers: [
        { text: 'Name', value: 'name' },
        { text: 'Location', value: 'location' },
        { text: 'Primary contact', value: 'primaryContactFullName' },
        { text: 'Edit', value: 'action', sortable: false, width: 130 }
      ],
      tableItems: [],
      editedItem: {},
      userSelected: {},
      userItems: [],
      newItem: false,
      reqRules: [
        v => !!v || 'Field is required',
      ],
      isFormValid: false,
    }
  },
  created() {
    this.getitems();
    EventBus.$on('updateworkspaceitems', () => {
      this.getitems();
    });
  },
  computed: {
    ...mapGetters([
      'workspaceid',
      'organizationid'
    ]),
  },
  methods: {
    getitems() {
      HTTP.get('/workspace/get/' + this.organizationid).then(response => {
        this.tableItems = response.data
      }).catch(e => {
        this.$store.dispatch('showError', e.response.data)
      })
    },
    save() {
      var self = this;
      self.editedItem.primaryContactId = self.userSelected.id
      self.editedItem.primaryContactFullName = `${self.userSelected.firstname} ${self.userSelected.lastname}`
      HTTP.post('/workspace/save', self.editedItem).then(response => {

        if (self.editedItem.id > 0) {
          var editedIndex = self.tableItems.findIndex(q => q.id === self.editedItem.id);
          self.tableItems[editedIndex].name = self.editedItem.name;
          self.tableItems[editedIndex].location = self.editedItem.location;
          self.tableItems[editedIndex].primaryContactId = self.editedItem.primaryContactId;
          self.tableItems[editedIndex].primaryContactFullName = self.editedItem.primaryContactFullName;
        } else {
          self.editedItem.id = response.data
          self.tableItems.push(self.editedItem)
          self.$store.dispatch('addWorkspaceitem', self.editedItem)
        }
        self.dialog = false
      }).catch(e => {
        self.$store.dispatch('showError', 'Error when saving: ' + e.response.data)
        self.$router.push('/issues')
      })
    },
    newitem() {
      this.newItem = true
      this.editedItem = { id: 0, name: null, location: null, primaryContactId: 0, primaryContactFullName: null, organizationId: this.organizationid }
      this.editItem(this.editedItem)
    },
    editItem(item) {
      var self = this
      HTTP.get('/workspace/getusers/' + self.organizationid).then(response => {
        self.userItems = response.data
        self.userSelected = self.userItems.find(i => i.id === self.editedItem.primaryContactId)
      })
      self.editedItem = Object.assign({}, item)
      self.dialog = true
      setTimeout(() => {
        if (this.$refs.workspaceform) this.$refs.workspaceform.resetValidation()
      }, 50)
    },
    deleteItem(item) {
      const self = this
      this.$root.$confirm.open('Delete item', 'Please make sure no QR codes exists in this workspace before attemting to delete.<br/><br/>All associated Inventories, Inventory Types, QR Categories, Locations and Images will also be deleted.', { color: 'red' }).then((response) => {
        if (response) {
          HTTP.post('/workspace/delete', item).then(() => {
            self.tableItems.splice(self.tableItems.indexOf(item), 1);
            this.$store.dispatch('deleteWorkspaceitem', item)
            if (self.workspaceid == item.id){
              this.$store.dispatch('saveWorkspaceId', -1);
            }
          }).catch(e => {
            self.$store.dispatch('showError', e.response.data)
          })
        }
      })
    },
    getUserFullName(item) {
      return `${item.firstname} ${item.lastname}`;
    },
  }
}
</script>