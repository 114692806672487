<template>
  <v-app>
    <router-view></router-view>
    <v-dialog v-model="errordialog" persistent width="500">
      <v-card class="pa-4">
        <v-card-title class="headline">Message<v-icon large class="ml-3"
            color="red">mdi-alert-circle</v-icon></v-card-title>

        <v-card-text v-html="errormessage"></v-card-text>
        <v-card-actions class="justify-center">
          <v-btn tile @click="clearerror()">Ok</v-btn>
        </v-card-actions>
      </v-card>

    </v-dialog>
    <confirm ref="confirm"></confirm>
  </v-app>
</template>

<script>

import { HTTP } from '@/plugins/backendAPI'
import { mapGetters } from 'vuex'
import confirm from '@/components/confirm'

export default {
  name: 'App',

  components: { confirm },
  data() {
    return {
    }
  },
  mounted() {
    this.$root.$confirm = this.$refs.confirm
  },
  created() {
    this.checkCurrentLogin()
    this.$store.dispatch('initStorage')
  },
  computed: {
    ...mapGetters([
      'errordialog',
      'errormessage',
      'isauthorized',
      'bearertoken',
    ])
  },
  methods: {
    checkCurrentLogin() {
      if (this.$router.currentRoute.path.startsWith('/m/')) {
        this.checkAuthorization()
      }
      if (!this.$router.currentRoute.path.startsWith('/displaymap/') && !this.$router.currentRoute.path.startsWith('/m/') && !this.$router.currentRoute.path.startsWith('/changepassword/') || this.$router.currentRoute.path.startsWith('/m/edit')) {
        this.checkAuthorization()
        HTTP.get('/account/checkauthorization')
          .catch(e => {
            this.$store.dispatch('saveAuthorization', false)
            this.error = e
            this.$router.push({ name: 'Login' })
          })
      }
    },
    clearerror() {
      this.$store.dispatch('clearError')
    },
    checkAuthorization() {
      if (HTTP.defaults.headers.common['Authorization'] === undefined) {
        HTTP.defaults.headers.common['Authorization'] = 'Bearer ' + this.bearertoken
      }
    }
  }
}
</script>

<style></style>