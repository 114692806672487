<template>
  <div class="adminbackground">
    <appbar></appbar>
    <v-row justify="center" class="mt-5">
      <v-col md="9" sm="12">
        <v-data-table dense :headers="headers" :items="tableItems" hide-default-footer disable-pagination
          class="elevation-1">
          <template v-slot:top>
            <v-row class="d-flex align-start mx-5">
              <v-col cols=12>
                <h2>Forms</h2>
              </v-col>
            </v-row>
          </template>
          <template v-slot:item.action="{ item }">
            <v-icon mewdium class="pa-2" @click="editItem(item)">mdi-pencil</v-icon>
          </template>
        </v-data-table>



        <v-dialog v-model="dialog" max-width="800px" class="dialogbox" eager>
          <v-card>
            <v-card-title>
              <span class="headline">Edit</span>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-form v-model="isFormValid">
                  <v-row>
                    <v-col cols="6">
                      <v-text-field disabled v-model="editedItem.description" label="Description"></v-text-field>
                    </v-col>
                    <v-col cols="6">
                      <v-combobox item-value="id" :item-text="getFullName"
                        v-model="userSelected" :items="userItems" label="User"></v-combobox>
                    </v-col>
                  </v-row>
                </v-form>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn tile outlined class="mb-3" @click="dialog = false"><v-icon small class="py-2 mr-1">mdi-close</v-icon>
                Cancel</v-btn>
              <v-btn tile outlined class="mb-3 mr-3" @click="save()" :disabled="!isFormValid"><v-icon small
                  class="py-2 mr-1">mdi-content-save</v-icon> Save</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>


      </v-col>
    </v-row>
  </div>
</template>

<script>

import { HTTP } from '@/plugins/backendAPI'
import { mapGetters } from 'vuex'
import { EventBus } from '@/store/event-bus.js'

export default {
  data() {
    return {
      dialog: false,
      footerProps: { 'items-per-page-options': [50, 100] },
      headers: [
        { text: 'Description', value: 'description', width: "45%", sortable: false },
        { text: 'Notification receiver', value: 'fullName', sortable: false, width: "66%" },
        { text: 'Edit', value: 'action', sortable: false, width: 90 }
      ],
      detailheaders: [
        { text: 'Description', value: 'inventorytypeDescription' },
        { text: 'User', value: 'action' },
      ],

      tableItems: [],
      editedItem: {},
      detailFormObj: {},
      userSelected: {},
      userItems: [],
      isFormValid: false,
    }
  },
  created() {
    this.getitems()
    EventBus.$on('updateform', workspaceid => {
      this.getitems(workspaceid);
    })
  },
  computed: {
    ...mapGetters([
      'workspaceid'
    ]),
  },
  methods: {
    getitems() {
      HTTP.get('/form/get/' + this.workspaceid).then(response => {
        this.tableItems = response.data
      })
    },
    save() {
      var self = this
      const userId = self.userSelected ? self.userSelected.id : -1

      self.detailFormObj.formTypeId = self.editedItem.id
      self.detailFormObj.servicemeUserId = userId
      self.detailFormObj.workspaceId = self.workspaceid

      HTTP.post('/form/save', self.detailFormObj).then(() => {
        var editedIndex = this.tableItems.findIndex(q => q.id === self.editedItem.id)
        this.tableItems[editedIndex].servicemeUserId = userId === -1 ? 0 : self.userSelected.id
        this.tableItems[editedIndex].fullName = userId === -1 ? null : this.getFullName(self.userSelected)
        self.dialog = false
      }).catch(e => {
        self.$store.dispatch('showError', 'Error when saving: ' + e.response.data)
      })
    },
    editItem(item) {
      var self = this

      if (self.workspaceid == -1){
        self.$store.dispatch('showError', 'No workspace selected.<br/><br/>Please make sure you have created atleast one workspace and that it is selected.')
        return
      }
      
      self.editedItem = Object.assign({}, item)
      HTTP.get('/inventory/getusers/' + self.workspaceid).then(response => {
        self.userItems = response.data
        self.userSelected = self.userItems.find(i => i.id === self.editedItem.servicemeUserId)
        self.dialog = true
      })
    },
    getFullName(item) {
      return `${item.firstname} ${item.lastname}`;
    },
  },
}
</script>