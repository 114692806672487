<template>
  <div class="adminbackground">
    <appbar></appbar>
    <v-row justify="center" class="mt-5">
      <v-col md="9" sm="12">

        <v-data-table dense :search="search" :headers="headers" :items="tableItems" :sort-by.sync="sortOptions.sortBy"
          :sort-desc.sync="sortOptions.sortDesc" :options.sync="sortHandler" :items-per-page="20"
          :footer-props="footerProps" class="elevation-1">
          <template v-slot:top>
            <v-row class="d-flex align-start mx-5">
              <v-col cols=12>
                <h2>Inventory</h2>
              </v-col>
            </v-row>
            <v-row class="d-flex align-center mx-5">
              <v-col cols=8>
                <v-text-field v-model="search" label="Search"></v-text-field>
              </v-col>
              <v-icon large v-if="search.length > 0" class="close-button" @click="search = ''">mdi-close</v-icon>
              <v-col cols=3>
                <v-btn :class="search.length > 0 && 'margin-left-10'" tile outlined @click="newitem()"><v-icon small
                    class="py-2 mr-2">mdi-flip-to-front</v-icon> New</v-btn>
              </v-col>
            </v-row>
          </template>
          <template v-slot:item.action="{ item }">
            <v-icon mewdium class="mr-2 pa-2" @click="editItem(item)">mdi-pencil</v-icon>
            <v-icon medium class="pa-2" @click="deleteItem(item)">mdi-delete</v-icon>
          </template>
          <template v-slot:item.isActive="{ item }">
            <v-col class="switch-col">
              <v-switch @click="switchActive(item)" v-model="item.isActive" inset class="switch-btn"></v-switch>
            </v-col>
          </template>

          <template v-slot:item.isIssueWarningActive="{ item }">
            <v-col class="switch-col">
              <v-switch @click="switchActive(item)" v-model="item.isIssueWarningActive" inset
                class="switch-btn"></v-switch>
            </v-col>
          </template>

        </v-data-table>

        <v-dialog v-model="dialog" max-width="800px" class="dialogbox">
          <v-card>
            <v-card-title>
              <span class="headline">{{ this.editedItem.id === 0 ? 'New' : 'Edit' }}</span>
            </v-card-title>
            <v-card-text>
              <v-container>

                <v-form ref="newInventoryItem" v-model="isFormValid">
                  <v-row>
                    <v-col cols="6">
                      <v-text-field required :rules="reqRules" ref="description" v-model="editedItem.description"
                        label="Description"></v-text-field>
                    </v-col>
                    <v-col cols="6">
                      <v-combobox required :rules="reqRules" item-value="id" item-text="description"
                        v-model="inventorytypeSelected" :items="inventorytypeItems" label="Inventory type"></v-combobox>
                    </v-col>
                  </v-row>
                </v-form>

                <div>
                  <v-row>
                    <v-col cols="4">
                      <v-text-field v-model="editedItem.instructionHeader" label="Header" placeholder="Instructions"
                        dense></v-text-field>
                    </v-col>
                  </v-row>
                  <tiptap-vuetify v-model="editedItem.instruction"
                    placeholder="Write inventory specific instructions here..." :extensions="extensions" />
                </div>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn tile outlined class="mb-3" @click="dialog = false"><v-icon small class="py-2 mr-1">mdi-close</v-icon>
                Cancel</v-btn>
              <v-btn tile outlined class="mb-3 mr-3" @click="save" :disabled="!isFormValid"><v-icon small
                  class="py-2 mr-1">mdi-content-save</v-icon> Save</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-col>
    </v-row>
  </div>
</template>

<script>

import { HTTP } from '@/plugins/backendAPI'
import { TiptapVuetify, Heading, Bold, Italic, Strike, Underline, Paragraph, BulletList, OrderedList, ListItem, HardBreak, History, Link } from 'tiptap-vuetify'
import { mapGetters } from 'vuex'
import { EventBus } from '@/store/event-bus.js'

export default {
  components: { TiptapVuetify },
  data() {
    return {
      dialog: false,
      enableEscClear: true,
      search: '',
      footerProps: { 'items-per-page-options': [20, 50, 100] },
      headers: [
        { text: 'Description', value: 'description', width: '50%' },
        { text: 'Inventory type', value: 'inventoryType' },
        { text: 'Active issues warning', value: 'isIssueWarningActive', sortable: false },
        { text: 'Selectable on QR codes', value: 'isActive', sortable: false },
        { text: 'Edit', value: 'action', sortable: false }
      ],
      editedItem: {},
      tableItems: [],
      inventorytypeItems: [],
      inventorytypeSelected: null,
      reqRules: [
        v => !!v || 'Field is required',
      ],
      isFormValid: false,

      extensions: [
        History,
        // Blockquote,
        [Link, {
          options: {
            target: '_blank',
          }
        }],
        Bold,
        Underline,
        Strike,
        Italic,
        ListItem,
        BulletList,
        OrderedList,
        [Heading, {
          options: {
            levels: [1, 2, 3]
          }
        }],
        // Code,
        Paragraph,
        HardBreak,
      ],

      instructions: ``,

      sortHandler: {},
      sortOptions: {
        sortBy: [],
        sortDesc: [],
      },
    }
  },
  created() {
    this.getitems(this.workspaceid)
    this.sortOptions = this.sort.inventorySort || this.sortOptions
    EventBus.$on('updateinventory', workspaceid => {
      this.getitems(workspaceid);
    })
  },
  mounted() {
    document.addEventListener('keydown', this.clearSearchEsc)
  },
  beforeDestroy() {
    document.removeEventListener('keydown', this.clearSearchEsc)
  },
  computed: {
    ...mapGetters([
      'sort',
      'workspaceid'
    ]),
    isInstructionEmpty() {
      return this.editedItem.instruction === null ||
        this.editedItem.instruction === '' ||
        this.editedItem.instruction === '<p></p>' ||
        this.editedItem.instruction === '<h1></h1>' ||
        this.editedItem.instruction === '<h2></h2>' ||
        this.editedItem.instruction === '<h3></h3>';
    },
    isInstructionHeaderEmpty() {
      if (this.editedItem.instructionHeader === null || this.editedItem.instructionHeader === undefined) return true
      if (this.editedItem.instructionHeader.trim() === '') return true
      return false
    }
  },
  watch: {
    sortHandler: {
      deep: true,
      handler(obj) {
        const objSort = { sortBy: obj.sortBy, sortDesc: obj.sortDesc }

        if (JSON.stringify(objSort) !== JSON.stringify(this.sortOptions)) {
          const newObj = this.sort
          newObj.inventorySort = objSort
          this.$store.dispatch('updateSort', newObj)
        }
      }
    },
    dialog(val) {
      if (val) this.enableEscClear = false
      if (!val) {
        setTimeout(() => {
          this.enableEscClear = true
        }, 100)
      }
    },
  },
  methods: {
    getitems(workspaceid) {
      HTTP.get('/inventory/get/' + workspaceid).then(response => {
        this.tableItems = response.data
      })
    },
    newitem() {
      if (this.workspaceid === -1){
        this.$store.dispatch('showError', 'No workspace selected.<br/><br/>Please make sure you have created atleast one workspace and that it is selected.')
        return
      }
      this.editedItem = { id: 0, description: null, inventoryTypeId: 0, inventoryType: null, isActive: true, isIssueWarningActive: true }
      this.editItem(this.editedItem)
      if (this.$refs.newInventoryItem) this.$refs.newInventoryItem.reset()
    },
    editItem(item) {
      var self = this
      HTTP.get('/inventory/getinventorytypes/' + self.workspaceid).then(response => {
        self.inventorytypeItems = response.data
        self.editedItem = Object.assign({}, item)
        self.inventorytypeSelected = self.inventorytypeItems.find(i => i.id === item.inventoryTypeId)
        self.showDialog()
      })
    },
    showDialog() {
      this.dialog = true
      setTimeout(() => {
        this.$refs.description.focus()
      }, 200)
    },
    switchActive(item) {
      HTTP.post('/inventory/save', item).then(() => { }).catch(e => {
        this.$store.dispatch('showError', 'Error when saving: ' + e.response.data)
      })
    },
    deleteItem(item) {
      this.$root.$confirm.open('Delete item', 'Are you sure?', { color: 'red' }).then((response) => {
        if (response) {
          HTTP.post('/inventory/delete', item).then(() => {
            this.tableItems.splice(this.tableItems.indexOf(item), 1)
          }).catch(e => {
            this.$store.dispatch('showError', e.response.data)
          })
        }
      })
    },
    save() {
      var self = this;
      self.editedItem.inventoryTypeId = self.inventorytypeSelected.id;
      self.editedItem.inventoryType = self.inventorytypeSelected.description;
      self.editedItem.workspaceid = this.workspaceid;
      if (this.isInstructionEmpty) self.editedItem.instruction = null;
      if (this.isInstructionHeaderEmpty) self.editedItem.instructionHeader = null;
      HTTP.post('/inventory/save', self.editedItem).then(response => {
        if (self.editedItem.id > 0) {
          var editedIndex = self.tableItems.findIndex(q => q.id === self.editedItem.id);
          self.tableItems[editedIndex].description = self.editedItem.description;
          self.tableItems[editedIndex].inventoryType = self.editedItem.inventoryType;
          self.tableItems[editedIndex].instruction = self.editedItem.instruction;
          self.tableItems[editedIndex].instructionHeader = self.editedItem.instructionHeader;
        } else {
          self.editedItem.id = response.data;
          self.tableItems.push(self.editedItem);
        }
        self.dialog = false;
      }).catch((e) => {
        this.$store.dispatch('showError', 'Error when saving: ' + e.response.data)
      })
    },
    clearSearchEsc(e) {
      if (e.keyCode === 27 && this.enableEscClear) {
        this.search = ''
      }
    },
  }
}
</script>
