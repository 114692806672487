<template>
  <div class="adminbackground">
    <appbar></appbar>
    <v-row justify="center" class="mt-5">
      <v-col md="9" sm="12">
        <v-data-table dense :search="search" :headers="headers" :items="tableItems" :sort-by.sync="sortOptions.sortBy"
          :sort-desc.sync="sortOptions.sortDesc" :options.sync="sortHandler" :items-per-page="20"
          :footer-props="footerProps" class="elevation-1">
          <template v-slot:top>
            <v-row class="d-flex align-start mx-5">
              <v-col cols=12>
                <h2>Users</h2>
              </v-col>
            </v-row>
            <v-row class="d-flex align-center mx-5">
              <v-col cols=8>
                <v-text-field v-model="search" label="Search"></v-text-field>
              </v-col>
              <v-icon large v-if="search.length > 0" class="close-button" @click="search = ''">mdi-close</v-icon>
              <v-col cols=3>
                <v-btn :class="search.length > 0 && 'margin-left-10'" tile outlined @click="newitem()"><v-icon small
                    class="py-2 mr-2">mdi-flip-to-front</v-icon> New</v-btn>
              </v-col>
            </v-row>
          </template>
          <template v-slot:item.isAdministrator="{ item }">
            <v-simple-checkbox v-model="item.isAdministrator" disabled></v-simple-checkbox>
          </template>
          <template v-slot:item.action="{ item }">
            <v-icon medium class="pa-2" @click="editItem(item); newItem = false">mdi-pencil</v-icon>
            <v-icon medium class="pa-2" @click="deleteItem(item)">mdi-delete</v-icon>
          </template>
        </v-data-table>

        <v-dialog v-model="dialog" max-width="800px" class="dialogbox">
          <v-card>
            <v-card-title>
              <span class="headline">{{ this.editedItem.id === 0 ? 'New' : 'Edit' }}</span>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-form v-model="isFormValid" ref="userform">
                  <v-row>
                    <v-col>
                      <v-text-field required :rules="reqRules" ref="firstName" v-model="editedItem.firstname"
                        label="First name"></v-text-field>
                    </v-col>
                    <v-col>
                      <v-text-field required :rules="reqRules" v-model="editedItem.lastname"
                        label="Last name"></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-text-field required :rules="emailRules" validate-on-blur v-model="editedItem.email"
                        label="User name / e-mail"></v-text-field>
                    </v-col>
                    <v-col>
                      <!-- <v-text-field v-model="editedItem.lastname" label="Password"></v-text-field> -->
                      <v-text-field v-model="editedItem.password" :append-icon="showpassword ? 'mdi-eye' : 'mdi-eye-off'"
                        :rules="newItem ? newPassRules : editPassRules" :type="showpassword ? 'text' : 'password'"
                        label="Password" hint="At least 15 characters, 1 digit, 1 special character" counter
                        @click:append="showpassword = !showpassword" autocomplete="off"></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12">
                      <v-select v-model="editedItem.selectedworkspaces" :items="workspaceitems" item-value="id"
                        item-text="name" label="Workspaces" multiple hint="Select workspaces" persistent-hint></v-select>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12">
                      <v-checkbox class="d-inline-flex" v-model="editedItem.isAdministrator" label="Administrator"></v-checkbox>
                    </v-col>
                  </v-row>
                </v-form>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn tile outlined class="mb-3" @click="dialog = false"><v-icon small class="py-2 mr-1">mdi-close</v-icon>
                Cancel</v-btn>
              <v-btn tile outlined class="mb-3 mr-3" @click="save()" :disabled="!isFormValid"><v-icon small
                  class="py-2 mr-1">mdi-content-save</v-icon> Save</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

      </v-col>
    </v-row>
  </div>
</template>

<script>

import { HTTP } from '@/plugins/backendAPI'
import { mapGetters } from 'vuex'
import { EventBus } from '@/store/event-bus.js'

const emailRegex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;

// (?=.*\d) ensures at least one digit exists
// (?=.*[^\w\såäöæøÅÄÖÆØ]) ensures at least one special character exists, excluding Nordic letters without duplication.
// .{15,} ensures at least 15 characters of any type
const passWdRegex = /^(?=.*\d)(?=.*[^\w\såäöæøÅÄÖÆØ]).{15,}$/;

export default {
  data() {
    return {
      dialog: false,
      enableEscClear: true,
      search: '',
      footerProps: { 'items-per-page-options': [20, 50, 100] },
      headers: [
        { text: 'First name', value: 'firstname', width: '25%' },
        { text: 'Last name', value: 'lastname', width: '25%' },
        { text: 'e-mail', value: 'email', width: '30%' },
        { text: 'Administrator', value: 'isAdministrator', width: '20%' },
        { text: 'Edit', value: 'action', sortable: false, width: 130 }
      ],
      editedItem: {},
      tableItems: [],
      selectedworkspaces: [],
      workspaceitems: [],

      newPassRules: [
        v => !!v || 'Field is required',
        v => !!passWdRegex.test(v) || 'At least 15 characters, 1 digit, 1 special character',
      ],
      editPassRules: [
        v => (!!v === false || v !== null && !!passWdRegex.test(v)) || 'At least 15 characters, 1 digit, 1 special character',
      ],
      reqRules: [
        v => !!v || 'Field is required',
      ],
      emailRules: [
        v => !!v || 'Field is required',
        v => !!emailRegex.test(v) || 'Email is in incorrect format',
      ],
      isFormValid: false,
      showpassword: false,
      newItem: false,

      sortHandler: {},
      sortOptions: {
        sortBy: [],
        sortDesc: [],
      },
    }
  },
  computed: {
    ...mapGetters([
      'sort',
      'organizationid'
    ]),
  },
  watch: {
    sortHandler: {
      deep: true,
      handler(obj) {
        const objSort = { sortBy: obj.sortBy, sortDesc: obj.sortDesc }

        if (JSON.stringify(objSort) !== JSON.stringify(this.sortOptions)) {
          const newObj = this.sort
          newObj.userSort = objSort
          this.$store.dispatch('updateSort', newObj)
        }
      }
    },
    dialog(val) {
      if (val) this.enableEscClear = false
      if (!val) {
        setTimeout(() => {
          this.enableEscClear = true
        }, 100)
      }
    },
  },
  created() {
    this.getitems();
    this.getworkspaces();
    EventBus.$on('updateuseritems', () => {
      this.getitems();
      this.getworkspaces();
    })
    this.sortOptions = this.sort.userSort || this.sortOptions;
  },
  mounted() {
    document.addEventListener('keydown', this.clearSearchEsc)
  },
  beforeDestroy() {
    document.removeEventListener('keydown', this.clearSearchEsc)
  },
  methods: {
    getitems() {
      HTTP.get('/users/get/' + this.organizationid).then(response => {
        this.tableItems = response.data
      })
    },
    getworkspaces() {
      HTTP.get('/workspace/get/' + this.organizationid).then(response => {
        this.workspaceitems = response.data
      })
    },
    newitem() {
      this.newItem = true
      this.editedItem = { id: 0, firstname: null, lastname: null, email: null, password: null, organizationid: this.organizationid }
      this.editItem(this.editedItem)
    },
    editItem(item) {
      this.editedItem = Object.assign({}, item)
      this.showpassword = false;
      this.dialog = true
      setTimeout(() => {
        this.$refs.firstName.focus()
      }, 200)
      if (this.$refs.userform) this.$refs.userform.resetValidation()
    },
    deleteItem(item) {
      var self = this
      this.$root.$confirm.open('Delete item', 'Are you sure?', { color: 'red' }).then((response) => {
        if (response) {
          HTTP.post('/users/delete', { id: item.id }).then(() => {
            self.tableItems.splice(self.tableItems.indexOf(item), 1)
          }).catch(e => {
            self.$store.dispatch('showError', e.response.data)
          })
        }
      })
    },
    save() {
      var self = this
      HTTP.post('/users/save', self.editedItem).then(response => {
        if (self.editedItem.id > 0) {
          var editedIndex = self.tableItems.findIndex(q => q.id === self.editedItem.id);
          self.tableItems[editedIndex].firstname = self.editedItem.firstname;
          self.tableItems[editedIndex].lastname = self.editedItem.lastname;
          self.tableItems[editedIndex].email = self.editedItem.email;
          self.tableItems[editedIndex].selectedworkspaces = self.editedItem.selectedworkspaces;
          self.tableItems[editedIndex].isAdministrator = self.editedItem.isAdministrator;
        } else {
          self.editedItem.id = response.data
          self.tableItems.push(this.editedItem)
        }
        self.dialog = false
      }).catch(e => {
        self.$store.dispatch('showError', 'Error when saving:<br/><br/>' + e.response.data)
      })
    },
    clearSearchEsc(e) {
      if (e.keyCode === 27 && this.enableEscClear) {
        this.search = ''
      }
    },
  }
}
</script>