<template>
    <div>
        <mobileappbar header="Success"></mobileappbar>
        <div class="text-center mb-5 mt-10">QR Code was successfully {{ isEditMode ? "edited!" : "registered!" }}</div>
        <v-row justify="center"><i class="buttonicon far fa-thumbs-up"></i></v-row>
    </div>
</template>

<script>
export default {
  props: ['isEditMode'],
  data () {
    return {}
  },
}
</script>
    