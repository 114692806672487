import Vue from 'vue'
import VueRouter from 'vue-router'
import Issues from '@/views/Admin/Issues.vue'
import Qr from '@/views/Admin/Qr.vue'
import QrCategory from '@/views/Admin/QrCategory.vue'
import Inventory from '@/views/Admin/Inventory.vue'
import Inventorytype from '@/views/Admin/Inventorytype.vue'
import Statistics from '@/views/Admin/Statistics.vue'
import Users from '@/views/Admin/Users.vue'
import Organization from '@/views/Admin/Organization.vue'
import Workspace from '@/views/Admin/Workspace.vue'
import Login from '@/views/Login'
import Logout from '@/views/Logout'
import RecoverPassword from '@/views/RecoverPassword'
import ChangePassword from '@/views/ChangePassword'
import Mobile from '@/views/Mobile'
import MobileReport from '@/views/MobileReport'
import MobileReportSuccess from '@/views/MobileReportSuccess'
import MobileForm from '@/views/MobileForm'
import QRImage from '@/views/QRImage'
import MobileEdit from '@/views/MobileEdit'
import MobileEditSuccess from '@/views/MobileEditSuccess'
import Form from '@/views/Admin/Form'
import Location from '@/views/Admin/Location'
import MapView from '@/views/MapView'


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/issues',
  },
  {
    path: '/issues',
    name: 'Issues',
    component: Issues,
    props: true
  },
  {
    path: '/qr',
    name: 'qr',
    component: Qr,
    props: true
  },   
  {
    path: '/qrcategory',
    name: 'Qrcategory',
    component: QrCategory
  },
  {
    path: '/form',
    name: 'Form',
    component: Form
  },
  {
    path: '/location',
    name: 'Location',
    component: Location
  } ,    
  {
    path: '/inventory',
    name: 'Inventory',
    component: Inventory
  },     
  {
    path: '/inventorytype',
    name: 'Inventorytype',
    component: Inventorytype
  },
  {
    path: '/qrimage/:qrstring',
    name: 'QRImageLegacy',
    props: true,
    component: QRImage
  },
  {
    path: '/qrimage/:workspaceid/:qrworkspaceid',
    name: 'QRImage',
    props: true,
    component: QRImage
  },     
  {
    path: '/m/:qrstring',
    name: 'MobileLegacy',
    props: true,
    component: Mobile
  },
  {
    path: '/m/:workspaceid/:qrworkspaceid',
    name: 'Mobile',
    props: true,
    component: Mobile
  },
  {
    path: '/m/report/:workspaceid/:qrworkspaceid/:qrdetailid',
    props: true,
    name: 'MobileReport',
    component: MobileReport
  },
  {
    path: '/m/form/:formtype/:workspaceId/:qrworkspaceid',
    props: true,
    name: 'MobileForm',
    component: MobileForm
  }, 
  {
    path: '/mobilesent',
    name: 'MobileReportSuccess',
    component: MobileReportSuccess
  },
  {
    path: '/m/edit/success',
    name: 'MobileEditSuccess',
    props: true,
    component: MobileEditSuccess
  },   
  {
    path: '/m/edit/:workspaceid/:qrworkspaceid',
    name: 'EditMobile',
    props: true,
    component: MobileEdit
  },
  {
    path: '/displaymap/:imageid/:latlng',
    name: 'MapPage',
    props: true,
    component: MapView
  },
  {
    path: '/statistics',
    name: 'Statistics',
    component: Statistics
  },  
  {
    path: '/users',
    name: 'Users',
    component: Users
  },
  {
    path: '/organization',
    name: 'Organization',
    component: Organization
  },
  {
    path: '/workspace',
    name: 'Workspace',
    component: Workspace
  },  
    
  {
    path: '/login',
    name: 'Login',
    props: true,
    component: Login
  },
  {
    path: '/logout',
    name: 'Logout',
    component: Logout
  },
  {
    path: '/recoverpassword',
    name: 'RecoverPassword',
    component: RecoverPassword
  },  
  {
    path: '/changepassword/:guid',
    name: 'ChangePassword',
    props: true,
    component: ChangePassword
  },    
]

const router = new VueRouter({
  mode: 'history',
  // eslint-disable-next-line
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if (to.name === 'Login' || to.name === 'ChangePassword' || to.name === 'RecoverPassword' || to.name.startsWith('Mobile') || to.name === 'MapPage') {
    next()
  }
  else if ((localStorage.isauthorized === 'undefined' || localStorage.isauthorized === undefined || localStorage.isauthorized === false)) {
    if (from.name === 'Mobile' || from.name === 'MobileLegacy'){
      next({ name: 'Login' , params: { fromRoute: from }})
    }
    else {
      next("/Login")
    }
  } else {
      next()
  }
})

export default router
