<template>
  <div class="adminbackground">
    <appbar></appbar>
    <v-row justify="center" class="mt-5">
      <v-col md="9" sm="12">

        <v-data-table :search="search" :headers="headers" :items="tableItems" :sort-by.sync="sortOptions.sortBy"
          :sort-desc.sync="sortOptions.sortDesc" :options.sync="sortHandler" :items-per-page="50"
          :footer-props="footerProps" :custom-filter="filter" class="elevation-1">
          <template v-slot:top>
            <v-row class="d-flex align-start mx-5">
              <v-col cols=12>
                <h2>Issues</h2>
              </v-col>
            </v-row>
            <v-row class="d-flex align-center mx-5">
              <v-col cols=8>
                <v-text-field v-model="search" label="Search"></v-text-field>
              </v-col>
              <v-icon large v-if="search.length > 0" class="close-button" @click="search = ''">mdi-close</v-icon>
              <v-col cols=3>
                <v-checkbox :class="search.length > 0 && 'margin-left-10'" v-model="showclosed" label="Show closed"
                  @click="getitems(workspaceid)"></v-checkbox>
              </v-col>
            </v-row>
          </template>

          <template v-slot:body="{ items }">
            <tbody>
              <tr v-for="(item, index) in items" :key="index" @click="showdetailsdialog(item)" class="issue-list">
                <td>{{ item.type }}</td>
                <td>{{ item.header }}</td>
                <td>{{ item.name }}</td>
                <td>{{ item.created | longdate }}</td>
                <td>
                  <v-menu :nudge-width="40" offset-y>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn width="100px" :color="getStatus(item.status)" rounded dark v-bind="attrs" v-on="on"
                        @click="showstatusdialog(item)">
                        {{ getStatustext(item.status) }}
                      </v-btn>
                    </template>
                    <v-card>
                      <v-card-subtitle class="text-center pt-1">Change status</v-card-subtitle>
                      <v-card-text>
                        <div class="text-center"><v-btn rounded width="100px" @click="setStatus(0)" :color="getStatus(0)"
                            dark>Waiting</v-btn></div>
                        <div class="my-3 text-center"><v-btn rounded width="100px" @click="setStatus(1)"
                            :color="getStatus(1)" dark>Ongoing</v-btn></div>
                        <div class="text-center"><v-btn rounded width="100px" @click="setStatus(2)" :color="getStatus(2)"
                            dark>Closed</v-btn></div>
                      </v-card-text>
                    </v-card>
                  </v-menu>
                </td>
              </tr>
            </tbody>
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <v-dialog v-model="detailsdialog" max-width="1400px" min-height="1000px" class="dialogbox" eager>
      <v-card>
        <v-card-title>
          <span class="headline">Details - {{ getReportType }}</span>
        </v-card-title>
        <v-tabs background-color="transparent" color="black" left>
          <v-tab>Details</v-tab>
          <v-tab v-if="!!currentItemCoords && !!currentItemImageName" @click="showMapComponent">Map</v-tab>
          <v-tab-item v-for="n in 2" :key="n">
            <v-container fluid>
              <v-row v-if="n === 1">
                <v-col class="detail-text">
                  <v-textarea solo height="100%" rows=12 readonly :value="selecteditem.detailsText"
                    class="message"></v-textarea>
                </v-col>
                <v-col>
                  <a :href=pictureurl(selecteditem.picturename) target="_blank"><img v-if="selecteditem.picturename"
                      class="issue-image" :src="pictureurl(selecteditem.picturename)" /></a>
                </v-col>
              </v-row>

              <v-row v-if="n === 2">
                <div class="map-area-general">
                  <MapComponent v-if="showMap" :qrId="-1" :name="currentItemImageName" :latLng="currentItemCoords" />
                </div>
              </v-row>
            </v-container>
          </v-tab-item>
        </v-tabs>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn tile outlined class="mb-3" @click="detailsdialog = false"><v-icon small
              class="py-2 mr-1">mdi-close</v-icon> Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </div>
</template>

<script>

import { HTTP } from '@/plugins/backendAPI'
import { mapGetters } from 'vuex'
import MapComponent from '../../components/MapComponent.vue'
import { EventBus } from '@/store/event-bus.js'

export default {
  props: {
    showClosed: {
      type: Boolean,
      default: false
    },
    inventoryId: {
      type: String,
      default: ''
    }
  },
  components: {
    MapComponent,
  },
  data() {
    return {
      search: this.inventoryId,
      enableEscClear: true,
      showclosed: this.showClosed,
      footerProps: { 'items-per-page-options': [20, 50, 100] },
      headers: [
        { text: 'Type', value: 'type' },
        { text: 'Header', value: 'header', width: '45%' },
        { text: 'Responsible', value: 'name' },
        { text: 'Created', value: 'created' },
        { text: 'Status', value: 'status' },
      ],
      editedItem: {},
      tableItems: [],
      inventorytypeItems: [],
      inventorytypeSelected: null,
      selecteditem: {},
      detailsdialog: false,

      sortHandler: {},
      sortOptions: {
        sortBy: [],
        sortDesc: [],
      },

      showMap: false,
      currentItemCoords: '',
      currentItemImageName: '',
    }
  },
  computed: {
    ...mapGetters([
      'sort',
      'workspaceid'
    ]),
    getReportType() {
      if (this.selecteditem.type === 'Contact request' || this.selecteditem.type === 'Feedback') return this.selecteditem.type
      return 'Fault report'
    },
  },
  created() {
    this.getitems(this.workspaceid)
    this.sortOptions = this.sort.issueSort || this.sortOptions
    EventBus.$on('updateissues', workspaceid => {
      this.getitems(workspaceid);
    })
  },
  mounted() {
    document.addEventListener('keydown', this.clearSearchEsc)

  },
  beforeDestroy() {
    document.removeEventListener('keydown', this.clearSearchEsc)
  },
  watch: {
    sortHandler: {
      deep: true,
      handler(obj) {
        const objSort = { sortBy: obj.sortBy, sortDesc: obj.sortDesc }

        if (JSON.stringify(objSort) !== JSON.stringify(this.sortOptions)) {
          const newObj = this.sort
          newObj.issueSort = objSort
          this.$store.dispatch('updateSort', newObj)
        }
      }
    },
    detailsdialog(val) {
      if (val) this.enableEscClear = false
      if (!val) {
        this.showMap = false
        this.currentItemCoords = ''
        this.currentItemImageName = ''
        setTimeout(() => {
          this.enableEscClear = true
        }, 100)
      }
    },
  },
  methods: {
    getitems(workspaceid) {
      HTTP.get('/issues/get/' + this.showclosed + "/" + workspaceid).then(response => {
        this.tableItems = response.data
      })
    },
    showstatusdialog(item) {
      this.selecteditem = item
    },
    showMapComponent(e) {
      const value = e.target.innerText
      if (value === 'MAP') {
        setTimeout(() => {
          this.showMap = true
        }, 100)
      }
    },
    getStatus(status) {
      switch (status) {
        case 0:
          return "red"
        case 1:
          return "orange"
        case 2:
          return "green"
      }
    },
    getStatustext(status) {
      switch (status) {
        case 0:
          return "Waiting"
        case 1:
          return "Ongoing"
        case 2:
          return "Closed"
      }
    },
    setStatus(status) {
      var self = this
      const previousStatus = self.selecteditem.status
      self.selecteditem.status = status

      HTTP.post('/issues/savestatus', { id: self.selecteditem.id, type: self.selecteditem.type, status: status }).then(() => { }).catch(e => {
        self.selecteditem.status = previousStatus
        this.$store.dispatch('showError', 'Error when setting status: ' + e.response.data)
      })
    },
    showdetailsdialog(item) {
      this.selecteditem = item
      this.currentItemCoords = item.latLng === null ? '' : item.latLng
      this.getImageName(item.imageId)
      this.detailsdialog = true
    },
    getImageName(imageId) {
      HTTP.get('/image/checkifimageexist/' + imageId).then(result => {
        this.currentItemImageName = result.data
      })
    },
    pictureurl(picturename) {
      // eslint-disable-next-line
      return process.env.VUE_APP_APIURL + '/shared/bilder/' + picturename
    },
    filter(value, search, item) {
      return Object.values(item).some(prop => {
        return String(prop).toLowerCase().includes(search.toLowerCase())
      })
    },
    clearSearchEsc(e) {
      if (e.keyCode === 27) {
        if (this.detailsdialog) {
          this.detailsdialog = false
          return
        }
        if (this.enableEscClear) this.search = ''
      }
    },
    updateitems(workspaceid) {
      this.getitems(workspaceid);
    }
  }
}
</script>